$(document).on('click touchstart', '#form-contact button.jqsubmit', function() {

    $('#form-contact p.error').html('');

    if ($('textarea#message').val().length < 20) {

        $('#form-contact p.error').html('Deine Nachricht muss mindedstens 20 Zeichen lang sein.');
        return false;
    }

    var formData = $('#form-contact').serialize();

    $.ajax({
        url: '/services/contact.php',
        method: 'POST',
        dataType: 'json',
        data: formData,
        success: function(response) {

            if (true === response.status) {

                document.getElementById('form-contact').reset();

                $('#modal-message h5').html('Geschafft');
                $('#modal-message .message').html('Vielen Dank für Deine Anfrage, wir werden uns bald bei Dir melden.');
                $('#modal-message').modal('toggle');
            }
            else {

                $('#form-contact p.error').html(response.data);
            }
        }
    });

    return false;
});

function slide() {

    var slide;
    var slides = [];

    slides.push({
        'img' : 'jumbotron-bg-1.jpg',
        'title' : 'Deine Träume und Ziele<br />immer dabei haben',
        'subtitle': 'Dein digitales Visionboard kannst Du auch auf dem Tablet und Smartphone nutzen'
    });

    slides.push({
        'img' : 'jumbotron-bg-2.jpg',
        'title' : 'Es ist Deine Vision,<br />lass sie Wirklichkeit werden',
        'subtitle': 'Schreibe, male, drehe, verschiebe, füge Fotos und Formen ein'
    });

    slides.push({
        'img' : 'jumbotron-bg-3.jpg',
        'title' : 'So bunt wie Dein Leben,<br /> ist auch Deine Vision',
        'subtitle': 'Unendlich viele Farben und tausende kostenfreie Bilder'
    });

    if (typeof $('.jumbotron').data('slide') != 'undefined') {

        if ($('.jumbotron').data('slide') == slides.length) {
            slide = 1;
        }
        else {
            slide = (parseInt($('.jumbotron').data('slide')) + 1);
        }
    }
    else {
        slide = 2;
    }

    $('.jumbotron').css({
        'background-image': 'url(/build/website/img/'+slides[(slide-1)].img+')',
        'background-position': '50% 40%'
    }).animate({
        'background-position-x': '50%',
        'background-position-y': '90%'}, 9000, function() {
            slideNow();
        });

    $('.jumbotron .jumbotron-heading').html(slides[(slide-1)].title);
    $('.jumbotron .jumbotron-subheading').html(slides[(slide-1)].subtitle);

    $('.jumbotron').data('slide', slide);
}

function slideNow() {
    setTimeout("slide()", 10000);
}

$(function() {

    if (location.pathname == '/index.php' || location.pathname == '/index' || location.pathname == '/') {

        setTimeout(function() {
            $('.jumbotron').animate({
                'background-position-x': '50%',
                'background-position-y': '90%'}, 9000, function() {
                    slideNow();
                });
        }, 1500);
    }
});

$(document).on('click', '#cookie-allow', function() {
    setCookie('trackingCookieAllow', 'true');
    location.reload();
});

$(document).on('click', '#cookie-disallow', function() {
    setCookie('trackingCookieAllow', 'false');
    location.reload();
});

$(document).on('click', '#cookie-more', function() {
    window.location.href = '/datenschutz';
});

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}